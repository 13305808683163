import { Controller } from "@hotwired/stimulus";
import { throttle } from "lodash";

export default class extends Controller {
  static targets = ["scrollable"];
  static values = {
    amount: { type: Number, default: 300 },
  };

  scrollLeft() {
    this.scrollableTarget.scrollBy({
      left: -this.amountValue,
      behavior: "smooth",
    });
  }

  scrollRight() {
    this.scrollableTarget.scrollBy({
      left: this.amountValue,
      behavior: "smooth",
    });
  }
}