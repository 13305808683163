import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";

export default class extends Controller {  
  goBack() {
    if (document.referrer) {
      history.back();
    } else {
      Turbo.visit("/");
    }
  }
}