import { Controller } from '@hotwired/stimulus'
import { useIntersection } from 'stimulus-use'

export default class extends Controller {
  initialize () {
    useIntersection(this)
  }

  appear () {
    this.element.click()
  }
}