import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["content", "toggle"];

  connect() {
    this.expanded = false;
  }

  toggle() {
    this.expanded = !this.expanded;
    this.updateUI();
  }

  updateUI() {
    if (this.expanded) {
      this.contentTarget.classList.remove("line-clamp-6");
      this.toggleTarget.textContent = "Read Less";
    } else {
      this.contentTarget.classList.add("line-clamp-6");
      this.toggleTarget.textContent = "Read More";
    }
  }
}