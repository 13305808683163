import { Controller } from '@hotwired/stimulus';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import { isMobile } from "@/support/platform_helpers";

export default class extends Controller {
  static values = {
    content: String,
  }

  connect() {
    if (!isMobile) {
      tippy(this.element, {
        content: this.contentValue,
        arrow: false,
        delay: [800, 0],
      });
    }
  }
}